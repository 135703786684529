<template>
  <div class="page">
    <div class="main">
      <img
        src="../../../assets/attraction/default_background@1x.png" 
        draggable="false"
        oncontextmenu="return false;"
        class="background unselectable"
      >
      <div class="title">苗仓地被直采中心</div>
      <img
        src="../../../assets/attraction/default@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="unselectable"
      />
    </div>
    <div class="footer">
      <el-button class="large-button" @click="toApplyPage">申请入驻</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultAttraction',

  methods: {
    /* 申请入驻的点击事件处理(跳转到华南项目的申请入驻H5页) */
    toApplyPage() {
      this.$router.push({
        name: 'mBusiness'
      })
      // window.open('https://sc.miaocang.cc/#/mBusiness')
      // wx.miniProgram.navigateTo({
      //   url: '/pages/webView/webView?src=https://sc.miaocang.cc/#/mBusiness'
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  // background-color: #ebf9f3;
  .main {
    position: relative;
    padding: 28px 0 88px;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .title {
      margin: 20px 0 48px;
      text-align: center;
      font-size: 30px;
      line-height: 48px;
      letter-spacing: 1px;
      font-weight: 600;
      color: #146e4f;
    }
    img {
      width: 100vw;
      object-fit: cover;
    }
    .text {
      margin-top: 28px;
      padding: 0 14px;
      text-align: left;
      font-size: 14px;
      color: #fff;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding: 0 0 28px;
    line-height: 79px;
    text-align: center;
    background-color: #fff;
    z-index: 1000;
    .half-button {
      width: 43.6vw;
      &:first-child {
        margin-right: 14px;
      }
    }
    .large-button {
      width: 92vw;
      height: 60px;
      font-size: 20px;
      color: #fff;
      border-color: #00ae66;
      background-color: #00ae66;
    }
  }
}
</style>